<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="코스트센터 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      rowKey="costCenterCd"
      :isExcelDown="false"
      :isFullScreen="false"
      :columnSetting="false"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'cost-center-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: null,
          maintenanceFlag: null,
          purchaseFlag: null,
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:160px',
            sortable: true,
          },
          {
            name: 'costCenterNo',
            field: 'costCenterNo',
            label: '코스트센터 번호',
            align: 'center',
            style: 'width:160px',
            sortable: true,
          },
          {
            name: 'costCenterName',
            field: 'costCenterName',
            label: '코스트센터 명',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
        height: '450px'
      },
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
        maintenanceFlag: null,
        purchaseFlag: null,
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 450;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.costcenter.list.url;
      if (this.plantCd !== null) {
        this.searchParam.plantCd =  this.plantCd;
      }
      if (this.maintenanceFlag !== null) {
        this.searchParam.maintenanceFlag =  this.maintenanceFlag;
      }
      if (this.purchaseFlag !== null) {
        this.searchParam.purchaseFlag =  this.purchaseFlag;
      }
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '복사대상 PM마스터를 선택하세요.', // 복사대상 PM마스터를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
